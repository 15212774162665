import React, { useState, useLayoutEffect } from 'react'
import cn from 'classnames'
import Button from './button'
import s from './button.module.css'

const START_ID = 'stickyButtonStart'
const END_ID = 'stickyButtonEnd'
const DOM_LOADING_DELAY = 500
const INITIAL_STYLE = 2000

const StickyButton = props => {
  const [styles, setStyles] = useState({
    height: INITIAL_STYLE,
    top: INITIAL_STYLE,
  })

  useLayoutEffect(() => {
    const handleHeight = () => {
      setTimeout(() => {
        const start =
          document.getElementById(START_ID).getBoundingClientRect().top +
          document.documentElement.scrollTop
        const end =
          document.getElementById(END_ID).getBoundingClientRect().top +
          document.documentElement.scrollTop

        setStyles({ height: end - start, top: start })
      }, DOM_LOADING_DELAY)
    }

    window.addEventListener('DOMContentLoaded', handleHeight)
    window.addEventListener('resize', handleHeight)
    return () => {
      window.removeEventListener('DOMContentLoaded', handleHeight)
      window.removeEventListener('resize', handleHeight)
    }
  }, [])

  const [isLoaded, setIsLoaded] = useState(false)
  useLayoutEffect(() => setIsLoaded(true), [])

  return (
    <div
      className={cn(s.stickyHolder, { [s.loaded]: isLoaded })}
      style={{ ...styles }}
    >
      <Button href={props.href}>{props.children}</Button>
    </div>
  )
}

export default StickyButton
