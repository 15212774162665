import React from 'react'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import theme from 'components/base/theme'
import Section from './section'
import s from './chief.module.css'
import MrChief from './static/mrchief.png'

const WhoIsChief = () => (
  <Section className={s.light} background={theme.yellow}>
    <BalloonHolder>
      <Balloon isRight>Кто будет шефом?</Balloon>
      <Balloon>Николай Товеровский. Рад знакомству</Balloon>
    </BalloonHolder>
    <img className={s.mrchief} src={MrChief} alt='Товеровский' />
    <p>
      С&nbsp;2009 года я работаю с&nbsp;Дизайн-бюро Артёма&nbsp;Горбунова,
      несколько проектов для&nbsp;примера:{' '}
      <a href='https://bureau.ru/projects/onetwotrip/'>Раз-два-трип</a>,{' '}
      <a href='https://bureau.ru/projects/glavbukh-print/'>Журнал «Главбух»</a>,{' '}
      <a href='https://bureau.ru/projects/amundsen-and-scott/'>
        Диаграмма экспедиций Амундсена и&nbsp;Скотта
      </a>
      .
    </p>
    <p>
      Ещё в&nbsp;бюро я делаю разное на&nbsp;тему управления проектами:{' '}
      <a href='https://bureau.ru/bb/soviet/sinelnikov-toverovsky/'>советы</a>,{' '}
      <a href='https://bureau.ru/school/'>школу</a>,{' '}
      <a href='https://bureau.ru/projects/book-fff/'>книгу</a>.
    </p>
    <p>
      Помог «Додо Пицце» сделать:{' '}
      <a href='https://ksoftware.livejournal.com/335478.html'>
        американский сайт
      </a>
      ,{' '}
      <a href='https://ksoftware.livejournal.com/319469.html'>
        промостраницу франшизы
      </a>
      ,{' '}
      <a href='https://ksoftware.livejournal.com/323040.html'>
        внутреннюю базу знаний
      </a>
      ,{' '}
      <a href='https://ksoftware.livejournal.com/324295.html'>
        сайт «Одного&nbsp;миллиарда»
      </a>
      .
    </p>
    <p>
      В&nbsp;DRIVE2.RU запустил:{' '}
      <a href='https://ksoftware.livejournal.com/354786.html'>
        мобильный сайт и&nbsp;приложения
      </a>
      ,{' '}
      <a href='https://ksoftware.livejournal.com/401204.html'>
        новые «Бизнес-акаунты»
      </a>
      .
    </p>
    <p>
      Занимаюсь собственными проектами: сайт&nbsp;
      <a href='http://fff.works'>ФФФ.воркс</a>,{' '}
      <a href='http://fff.works/octopus/'>Осьминожка навыков</a>,{' '}
      <a href='https://conspectus.io/'>Конспект</a>.
    </p>
  </Section>
)

export default WhoIsChief
