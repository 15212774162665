import React, { useState, useRef, useLayoutEffect } from 'react'
import cn from 'classnames'
import useWindow from 'helpers/useWindow'
import { ReactComponent as Rocket } from './static/rocket.svg'
import { ReactComponent as Fire } from './static/fire.svg'
import s from './chiefRocketAnimation.module.css'

const ChiefRocketAnimation = () => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  useLayoutEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight > top)
  }, [scrollTop, viewportHeight])

  return (
    <div ref={ref} className={cn(s.holder, { [s.visible]: isVisible })}>
      <Fire className={s.fire} />
      <Rocket className={s.rocket} />
    </div>
  )
}

export default ChiefRocketAnimation
