import React from 'react'
import { Helmet } from 'react-helmet'

const Header = () => (
  <Helmet>
    <title>ФФФ.воркс</title>
    <meta charset='UTF-8' />
    <meta content='width=device-width, initial-scale=1' name='viewport' />
    <meta property='og:url' content='http://fff.works/' />
    <meta property='og:title' content='ФФФ.воркс' />
    <meta
      property='og:description'
      content='ФФФ.воркс — это инвентарь руководителя. Проверенные инструменты и приёмы управления проектами, людьми и собой'
    />
    <meta
      property='og:image'
      content='http://fff.works/assets/img/facebook.png'
    />
  </Helmet>
)

export default Header
