import { useState, useLayoutEffect } from 'react'

const useWindow = () => {
  const [[scrollTop, viewportHeight], setWindow] = useState([null, null])

  useLayoutEffect(() => {
    const handleWindow = () =>
      setWindow([document.documentElement.scrollTop, window.innerHeight])

    window.addEventListener('scroll', handleWindow)
    window.addEventListener('resize', handleWindow)
    return () => {
      window.removeEventListener('scroll', handleWindow)
      window.removeEventListener('resize', handleWindow)
    }
  })

  return [scrollTop, viewportHeight]
}

export default useWindow
