import React from 'react'
import theme from 'components/base/theme'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import Face from 'components/chiefClientFace/chiefClientFace'
import Section from './section'
import { ReactComponent as Gold } from './static/gold.svg'
import { ReactComponent as Helmet } from './static/helmet.svg'
import { ReactComponent as Shark } from './static/shark.svg'
import s from './chief.module.css'

const ForWhom = () => (
  <Section className={s.light} background={theme.yellow}>
    <BalloonHolder>
      <Balloon isRight>Кому пригодится помощь шефа?</Balloon>
      <Balloon>Любому кто, затрудняется сам организовать личный рост</Balloon>
    </BalloonHolder>

    <h3>
      Примеры задач,
      <br />
      с&nbsp;которыми поможет шеф
    </h3>
    <ul>
      <li>
        Улучшить навыки{' '}
        {
          <Helmet
            style={{ position: 'relative', top: -2 }}
            className={s.icon}
          />
        }
        управления{' '}
      </li>
      <li>Возглавить отдел</li>
      <li>Систематизировать текущий проект</li>
      <li>Собрать сильную команду</li>
      <li>Подготовиться к&nbsp;стартапу</li>
      <li>
        Наладить отношения {<Shark className={s.icon} />}с&nbsp;сотрудниками
      </li>
      <li>Освоить новую профессию</li>
      <li>Повысить продуктивность</li>
      <li>
        Начать больше{' '}
        {<Gold className={s.icon} style={{ position: 'relative', top: -3 }} />}
        зарабатывать
      </li>
      <li>Научиться запускать проекты в&nbsp;срок</li>
      <li>Стать ведущим специалистом в&nbsp;компании</li>
    </ul>
    <Face />
  </Section>
)

export default ForWhom
