import React from 'react'

const Header = () => (
  <div className='header holder'>
    <nav className='header-menu'>
      <div className='menu'>
        <div className='menu-item'>ФФФ.воркс</div>
        <div className='menu-item'>
          <a className='menu-item-link' href='../blog/'>
            Блог
          </a>
        </div>
      </div>
      <div className='menu-item menu-item-last'>
        <a
          className='menu-item-link'
          href='https://t.me/fffworks'
          target='_blank'
          rel='noopener noreferrer'
        >
          Канал в телеграме
        </a>
      </div>
    </nav>
  </div>
)

export default Header
