import React from 'react'
import theme from 'components/base/theme'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import Section from './section'
import { ReactComponent as Archery } from './static/archery.svg'
import { ReactComponent as Cyclist } from './static/cyclist.svg'
import s from './chief.module.css'

const Meetings = () => (
  <Section background={theme.ultramarine}>
    <BalloonHolder>
      <Balloon isRight>Kак это работает?</Balloon>
      <Balloon>
        Вы созваниваетесь с&nbsp;шефом в&nbsp;скайпе, и&nbsp;он помогает
        наладить рост
      </Balloon>
    </BalloonHolder>
    <Archery className={s.archery} />
    <h3>На&nbsp;первой встрече</h3>
    <ol>
      <li>Определяетесь с&nbsp;целью</li>
      <li>Обсуждаете направление роста</li>
      <li>Придумываете, как в&nbsp;этом направлении двигаться</li>
      <li>Составляете план: задачи, книги, встречи</li>
    </ol>
    <Cyclist className={s.cyclist} />
    <h3>На&nbsp;следующих встречах</h3>
    <ol>
      <li>Обсуждаете прогресс и&nbsp;возникшие сложности</li>
      <li>Добавляете интересные задачи</li>
      <li>Если возникли проблемы&nbsp;— разбираетесь, как решить</li>
    </ol>
  </Section>
)

export default Meetings
