import React, { useState, useLayoutEffect } from 'react'
import cn from 'classnames'
import theme from 'components/base/theme'
import ChiefHelloAnimation from 'components/chiefHelloAnimation/chiefHelloAnimation'
import Section from './section'
import Header from './header'
import s from './chief.module.css'

const Hello = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  useLayoutEffect(() => setIsLoaded(true), [])

  return (
    <Section className={s.first} background={theme.purpur}>
      <Header />
      <div className={cn(s.helloAnimatedHeading, { [s.loaded]: isLoaded })}>
        <div className={s.birka}>Услуга</div>
        <h1 className={s.heading}>Посове&shy;товаться с&nbsp;шефом</h1>
      </div>
      <div
        className={`
          ${s.helloAnimatedDescription}
          ${isLoaded && s.loaded}
        `}
      >
        <p>
          Расти в&nbsp;профессии трудно. Трудно выбрать направление
          и&nbsp;придумать как двигаться вперёд. Трудно дисциплинировать себя,
          чтобы работать на&nbsp;рост. Трудно оценить прогресс.
        </p>
        <p>
          Чтобы расти эффективно, найдите наставника, который поможет составить
          план роста и&nbsp;не&nbsp;даст его провалить. Такой наставник&nbsp;—
          &laquo;шеф&raquo;.
        </p>
        <p>
          Шефом может быть кто угодно&nbsp;&mdash; коллега, друг или
          родственник. Если подходящего кандидата не&nbsp;нашлось, поможет
          профессиональный шеф. У&nbsp;нас как раз такой есть.
        </p>
        <div className={s.cta}>
          <div id='stickyButtonStart' />
        </div>
      </div>
      <ChiefHelloAnimation />
    </Section>
  )
}

export default Hello
