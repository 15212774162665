import React from 'react'

const Hello = () => (
  <div className='row module'>
    <div className='cols module'>
      <div className='col module'>
        <div className='col-in module'>
          <div className='heading'>
            <h1>
              Это инвентарь руководителя. Инструменты и&nbsp;приёмы управления
              проектами, людьми и&nbsp;собой
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Hello
