import React from 'react'
import { Helmet } from 'react-helmet'
import ym from 'react-yandex-metrika'
import StickyButton from 'components/button/stickyButton'
import Hello from './hello'
import ForWhom from './forWhom'
import Meetings from './meetings'
import HowMuch from './howMuch'
import WhoIsChief from './whoIsChief'
import Comments from './comments'
import Price from './price'
import s from './chief.module.css'

const Head = () => (
  <Helmet>
    <title>Посоветоваться с&nbsp;шефом</title>
    <meta property='og:url' content='http://fff.works/chief' />
    <meta property='og:title' content='Посоветоваться с&nbsp;шефом' />
    <meta
      property='og:description'
      content='Чтобы расти в&nbsp;профессии эффективно, найдите наставника, который поможет составить план роста и&nbsp;не&nbsp;даст его провалить.'
    />
    <meta
      property='og:image'
      content='http://fff.works/chief/facebook-chief.png'
    />
  </Helmet>
)

const Chief = () => (
  <React.Fragment>
    <Head />
    <div className='webpage' style={{ background: '#e44a66' }}>
      <div className={s.phone}>
        <StickyButton
          href='mailto:nick.toverovskiy@gmail.com?subject=%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC&amp;body=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC.%0A%0A%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0%20%D0%B8%D0%BB%D0%B8%20%D1%86%D0%B5%D0%BB%D1%8C%3A%0A%5B%D0%9D%D0%B0%D0%BF%D0%B8%D1%88%D0%B8%D1%82%D0%B5%2C%20%D1%87%D0%B5%D0%B3%D0%BE%20%D1%85%D0%BE%D1%82%D0%B8%D1%82%D0%B5%20%D0%B4%D0%BE%D0%B1%D0%B8%D1%82%D1%8C%D1%81%D1%8F%20%D0%B8%D0%BB%D0%B8%20%D0%B4%D0%BE%D1%81%D1%82%D0%B8%D1%87%D1%8C.%20%D0%95%D1%81%D0%BB%D0%B8%20%D0%BD%D0%B5%20%D0%B7%D0%BD%D0%B0%D0%B5%D1%82%D0%B5%20%E2%80%94%20%D0%BD%D0%B5%D1%82%20%D0%BF%D1%80%D0%BE%D0%B1%D0%BB%D0%B5%D0%BC%2C%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D1%8D%D1%82%D0%BE%20%D0%BF%D0%BE%D0%BB%D0%B5%20%D0%BF%D1%83%D1%81%D1%82%D1%8B%D0%BC%2C%20%D1%88%D0%B5%D1%84%20%D0%BF%D0%BE%D0%BC%D0%BE%D0%B6%D0%B5%D1%82%20%D1%81%D1%84%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%20%D0%BD%D0%B0%20%D0%B2%D1%81%D1%82%D1%80%D0%B5%D1%87%D0%B5%5D'
          onClick={() => ym('reachGoal', 'ASK_CHIEF_INTEXT')}
        >
          Записаться к&nbsp;шефу
        </StickyButton>
        <Hello />
        <ForWhom />
        <Meetings />
        <HowMuch />
        <WhoIsChief />
        <Comments />
        <Price />
      </div>
    </div>
  </React.Fragment>
)

export default Chief
