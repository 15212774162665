import React from 'react'
import theme from 'components/base/theme'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import Section from './section'
import Burov from './static/burov.png'
import Yumadilov from './static/yumadilov.png'
import Micaliuc from './static/micaliuk.png'
import Shkurat from './static/shkurat.png'
import s from './chief.module.css'

const Comments = () => (
  <Section background={theme.ultramarine}>
    <BalloonHolder>
      <Balloon isRight>А&nbsp;отзывы есть?</Balloon>
      <Balloon>
        Да.&nbsp;Если напишете свой, мы&nbsp;его тоже опубликуем
      </Balloon>
    </BalloonHolder>
    <h3>
      <img className={s.avatar} src={Burov} alt='Буров' />
      <a href='https://www.facebook.com/anatoly.rr/posts/10219541128343028'>
        Анатолий Буров
      </a>
    </h3>
    <p>
      «И&nbsp;вот тут наступает магия. Потому что Коля задаёт простые,
      но&nbsp;крайне важные вопросы, которые почему-то мне в&nbsp;голову вовремя
      не&nbsp;пришли. Предполагаю, что это какое-то фундаментальное свойство:
      пока бродишь в&nbsp;деревьях, сложно уследить за&nbsp;всем лесом, нужен
      взгляд со&nbsp;стороны. Какой цели я пытаюсь достигнуть? А&nbsp;компания?
      Как буду измерять успех? Где чекпоинты?
    </p>
    <p>
      Ещё Коля делится кучей разных конкретных и&nbsp;обобщённых примеров
      и&nbsp;даёт практические советы. Определить принципы руководства.
      Составить шаблоны типовых задач. Не&nbsp;эскалировать проблемы
      без&nbsp;крайней необходимости.
    </p>
    <p>
      Конечно, большинство этих штук уже есть в&nbsp;книжках. Моя проблема
      в&nbsp;том, что погребённые под&nbsp;слоями пассивного знания нужные идеи
      вовремя не&nbsp;всплывают. Спасибо Коле, что помогает их вытащить
      на&nbsp;свет. Дополнительно вдохновляет невероятное спокойствие
      и&nbsp;осознанность Коли. Это какой-то особый скилл: сохранять равновесие
      в&nbsp;любых ситуациях. Вдохновляет даже просто наблюдение за&nbsp;этим
      со&nbsp;стороны».
    </p>
    <h3>
      <img className={s.avatar} src={Micaliuc} alt='Микалюк' />
      <a href='https://www.facebook.com/anatolie.micaliuc/posts/10213262182777091'>
        Анатолий Микалюк
      </a>
    </h3>
    <p>
      «Моя цель не&nbsp;на&nbsp;один месяц, но&nbsp;промежуточные результаты уже
      есть. По&nbsp;одному из&nbsp;проектов расширились полномочия, стали
      доверять более сложные задачи. По&nbsp;другому&nbsp;— увеличилась базовая
      ставка. Вдобавок нашёл себе в&nbsp;команду дизайнера (правда, ещё
      не&nbsp;успели поработать вместе). Устаканил график публикаций
      в&nbsp;инстаграме и&nbsp;увеличил число подписчиков».
    </p>
    <h3>
      <img className={s.avatar} src={Yumadilov} alt='Юмадилов' />
      <a href='https://www.facebook.com/v.yumadilov/posts/3149455625094905'>
        Вадим Юмадилов
      </a>
    </h3>
    <p>
      «Ты научил меня подходам „Делать не&nbsp;значит сделать“, „Можно всё“
      и&nbsp;„ФФФ“. Сначала в&nbsp;статьях на&nbsp;ЖЖ, потом в&nbsp;совместных
      проектах. Теперь это моя прошивка и&nbsp;я автоматически применяю метод,
      если он нужен».
    </p>
    <h3>
      <img className={s.avatar} src={Shkurat} alt='Шкурат' />
      <a href='https://www.notion.so/mikhail/4411a290e20e47bdbaf800a68c5abb4e'>
        Михаил Шкурат
      </a>
      <span style={{ fontWeight: 'normal' }}>
        , <a href='http://labnauka.ru/'>Наука</a>
      </span>
    </h3>
    <p>
      «Главное для меня&nbsp;то, что каждая встреча это прорыв, в&nbsp;том
      смысле, что Николай круто помогает мне, с&nbsp;чётким и&nbsp;ясным
      видением, что смогу сделать за&nbsp;неделю, и&nbsp;ещё более лёгким
      пониманием как это сделать».
    </p>
  </Section>
)

export default Comments
