import React from 'react'
import s from './chief.module.css'

const Header = () => (
  <nav className={s.headerMenu}>
    <div className='menu'>
      <div className='menu-item'>
        <a className={s.menuItemLink} href='/'>
          ФФФ.воркс
        </a>
      </div>
      <div className='menu-item'>
        <a className={s.menuItemLink} href='../blog/'>
          Блог
        </a>
      </div>
    </div>
    <div className='menu-item menu-item-last'>
      <a
        className={s.menuItemLink}
        href='https://t.me/fffworks'
        target='_blank'
        rel='noopener noreferrer'
      >
        Канал в телеграме
      </a>
    </div>
  </nav>
)

export default Header
