import React from 'react'
import theme from 'components/base/theme'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import ChiefRocketAnimation from 'components/chiefRocketAnimation/chiefRocketAnimation'
import Section from './section'

const HowMuch = () => (
  <Section background={theme.purpur}>
    <BalloonHolder>
      <Balloon isRight>Сколько всего встреч нужно?</Balloon>
      <Balloon>Зависит от вашей задачи</Balloon>
    </BalloonHolder>
    <h3>Одна встреча для пинка</h3>
    <p>
      Этого хватит для начала роста. Шеф поможет определиться с&nbsp;целью
      и&nbsp;планом, а&nbsp;двигаться дальше будете уже самостоятельно.
    </p>
    <h3>Раз в&nbsp;неделю для турбоускорения</h3>
    <p>
      Быстрый темп для тех, кто хочет получить результат в&nbsp;кратчайшие
      сроки.
    </p>
    <h3>Раз в&nbsp;две-четыре недели для планомерного роста</h3>
    <p>
      Оптимальный темп для достижения стратегических целей. В&nbsp;промежутке
      между встречами будет достаточно времени, для самостоятельной работы,
      а&nbsp;шеф поможет скорректировать движение и&nbsp;не&nbsp;забить.
    </p>
    <ChiefRocketAnimation />
  </Section>
)

export default HowMuch
