import React, { useState, useRef, useLayoutEffect } from 'react'
import useWindow from 'helpers/useWindow'
import s from './balloon.module.css'

const Balloon = ({ isRight, children }) => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)

  useLayoutEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight * 0.9 > top)
  }, [scrollTop, viewportHeight])

  return (
    <div
      className={`
        ${s.balloon}
        ${!isVisible && s.hidden}
        ${isRight && s.isRight}
      `}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Balloon
