import React, { useState } from 'react'
import cn from 'classnames'
import theme from 'components/base/theme'
import Balloon from 'components/balloon/balloon'
import BalloonHolder from 'components/balloon/balloonHolder'
import Button from 'components/button/button'
import Section from './section'
import s from './chief.module.css'

const Price = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.target)

    setIsFetching(true)

    fetch(
      'https://script.google.com/macros/s/AKfycbw72pzS2-QfWH5HzF7MdiavAoyCnVPPBoCEbf2X6g/exec',
      {
        method: 'POST',
        body: data,
      }
    )
      .then(response => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then(() => {
        setIsFetching(false)
        setIsSubmit(true)
      })
      .catch(() => {
        setIsFetching(false)
        setIsError(true)
      })
  }

  return (
    <Section id='cta' background={theme.purpur}>
      <BalloonHolder>
        <Balloon isRight>Cколько стоит консультация шефа? </Balloon>
        <Balloon>
          Встреча с&nbsp;шефом стоит 5000&nbsp;₽ и&nbsp;длится час
        </Balloon>
      </BalloonHolder>
      <div className={s.contacts}>
        <p>
          Почта:{' '}
          <a href='mailto:nick.toverovskiy@gmail.com?subject=%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC&amp;body=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D1%81%20%D1%88%D0%B5%D1%84%D0%BE%D0%BC.%0A%0A%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0%20%D0%B8%D0%BB%D0%B8%20%D1%86%D0%B5%D0%BB%D1%8C%3A%0A%5B%D0%9D%D0%B0%D0%BF%D0%B8%D1%88%D0%B8%D1%82%D0%B5%2C%20%D1%87%D0%B5%D0%B3%D0%BE%20%D1%85%D0%BE%D1%82%D0%B8%D1%82%D0%B5%20%D0%B4%D0%BE%D0%B1%D0%B8%D1%82%D1%8C%D1%81%D1%8F%20%D0%B8%D0%BB%D0%B8%20%D0%B4%D0%BE%D1%81%D1%82%D0%B8%D1%87%D1%8C.%20%D0%95%D1%81%D0%BB%D0%B8%20%D0%BD%D0%B5%20%D0%B7%D0%BD%D0%B0%D0%B5%D1%82%D0%B5%20%E2%80%94%20%D0%BD%D0%B5%D1%82%20%D0%BF%D1%80%D0%BE%D0%B1%D0%BB%D0%B5%D0%BC%2C%20%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D1%8C%D1%82%D0%B5%20%D1%8D%D1%82%D0%BE%20%D0%BF%D0%BE%D0%BB%D0%B5%20%D0%BF%D1%83%D1%81%D1%82%D1%8B%D0%BC%2C%20%D1%88%D0%B5%D1%84%20%D0%BF%D0%BE%D0%BC%D0%BE%D0%B6%D0%B5%D1%82%20%D1%81%D1%84%D0%BE%D1%80%D0%BC%D1%83%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D1%82%D1%8C%20%D0%BD%D0%B0%20%D0%B2%D1%81%D1%82%D1%80%D0%B5%D1%87%D0%B5%5D'>
            nick.toverovskiy@gmail.com
          </a>
        </p>
        <p>
          Телеграм:{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://tlgg.ru/@nicktoverovskiy'
          >
            @nicktoverovskiy
          </a>
        </p>
      </div>
      <div className={s.cta}>
        <div style={{ padding: '20px 0' }}>
          <div id='stickyButtonEnd'></div>
        </div>
      </div>
      <div className={s.cta}>
        <p>Или подписаться на&nbsp;редкие советы по&nbsp;личному росту:</p>
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            name='email'
            autoComplete='email'
            required='email'
            placeholder='Электронная почта'
          />
          <Button small>
            <span
              className={cn(s.response, {
                [s.is__fetching]: isFetching,
                [s.is__submitted]: isSubmit || isError,
              })}
            >
              {!isSubmit && !isError ? 'Го!' : ''}
              {isSubmit ? '✓' : ''}
              {isError ? '×' : ''}
            </span>
          </Button>
        </form>
      </div>
      <div className={s.spacer} />
    </Section>
  )
}

export default Price
