import React from 'react'
import Head from 'components/head/head'
import Tools from 'components/tools/tools'
import Header from './header'
import ChiefBanner from './chiefBanner'
import Hello from './hello'

const Index = () => (
  <div>
    <Head />
    <div className='webpage'>
      <Header />
    </div>
    <div className='webpage'>
      <div className='rows holder'>
        <ChiefBanner />
        <Hello />
        <Tools />
      </div>
    </div>
    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <h2 className='shelf-h2'>Книги и&nbsp;советы</h2>
                    <div className='books-holder-first'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link'
                        href='https://bureau.ru/projects/book-fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div className='main-book' id='book'>
                          <img
                            className='gorilla'
                            src='http://fff.works/assets/img/gorilla.png'
                            alt=''
                          />
                          <img
                            className='cover'
                            src='http://fff.works/assets/img/book-fff.png'
                            alt=''
                          />
                          <img
                            className='shark'
                            src='http://fff.works/assets/img/shark.png'
                            alt=''
                          />
                          <p className='no-tablet no-mobile'>
                            Электронный учебник
                            <br />
                            с&nbsp;виртуальными заданиями
                            <br />
                            и&nbsp;проверкой знаний
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <div className='books-holder'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link '
                        href='https://bureau.ru/books/fff/demo/4/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book'
                          style={{
                            borderBottom: '1px solid rgba(0,0,0,0.1)',
                            backgroundImage:
                              'url(http://fff.works/assets/img/sdelat@2x.png)',
                          }}
                        ></div>
                      </a>

                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link  no-mobile'
                        href='https://bureau.ru/about/fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/fff@2x.png)',
                          }}
                        ></div>
                      </a>

                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link no-tablet no-mobile'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/azy-upravleniya-proektom/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book no-tablet no-mobile'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/azy@2x.png)',
                          }}
                        ></div>
                      </a>

                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link no-tablet no-mobile'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/rabota-po-fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book no-tablet no-mobile'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/work@2x.png)',
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <div className='books-holder tablet'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/azy-upravleniya-proektom/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book'
                          id='tips'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/azy@2x.png)',
                          }}
                        ></div>
                      </a>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/rabota-po-fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/work@2x.png)',
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <div className='books-holder mobile'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link'
                        href='https://bureau.ru/about/fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book'
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/fff@2x.png)',
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <div className='books-holder mobile'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/azy-upravleniya-proektom/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book '
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/azy@2x.png)',
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='wide'>
      <div className='shelf'>
        <div className='webpage'>
          <div className='rows holder'>
            <div className='row module '>
              <div className='cols module '>
                <div className='col module'>
                  <div className='col-in module'>
                    <div className='books-holder mobile'>
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='book-link no-tablet'
                        href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/rabota-po-fff/?utm_source=fff-works&utm_medium=index'
                      >
                        <div
                          className='book '
                          style={{
                            backgroundImage:
                              'url(http://fff.works/assets/img/work@2x.png)',
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='webpage'>
      <div className='rows holder'>
        <div className='row module'>
          <div className='cols module'>
            <div className='col module'>
              <div className='col-in module'>
                <h2 style={{ marginTop: '80px' }}>Обучение и&nbsp;практика</h2>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='book-link'
                  href='https://bureau.ru/school/managers/?utm_source=fff-works&utm_medium=index'
                >
                  <div className='card school' id='school'>
                    <img
                      className='mountain'
                      src='http://fff.works/assets/img/mountain-managers.png'
                      alt=''
                    />
                    <img
                      className='managers'
                      src='http://fff.works/assets/img/managers-school.png'
                      alt=''
                    />
                  </div>
                </a>
                <p className='card-description'>
                  Школа даёт билет в&nbsp;профессию руководителям проектов
                  и&nbsp;продуктов
                </p>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='book-link'
                  href='https://bureau.ru/educenter/fff/?utm_source=fff-works&utm_medium=index'
                >
                  <div
                    className='card'
                    id='course'
                    style={{
                      backgroundImage:
                        'url(http://fff.works/assets/img/kurs.jpg)',
                      backgroundSize: 'cover',
                    }}
                  >
                    <img
                      className='toverovskiy'
                      src='http://fff.works/assets/img/toverovskiy.png'
                      alt=''
                    />
                    <p className='kurs'>
                      <span>Курс Николая Товеровского</span>
                      <br />
                      Управление
                      <br />
                      проектами,
                      <br />
                      людьми
                      <br />
                      и&nbsp;собой
                    </p>
                  </div>
                </a>
                <p className='card-description-course'>
                  Цель курса&nbsp;&mdash; научить воплощать бескомпромиссные
                  проекты, запускать их&nbsp;точно в&nbsp;запланированный день
                  и&nbsp;управлять личным временем
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='webpage'>
      <div className='footer holder'>fff.works, 2019</div>
    </div>
  </div>
)

export default Index
