import React from 'react'
import cn from 'classnames'
import { ReactComponent as Face } from './static/face.svg'
import { ReactComponent as Magic } from './static/magic.svg'
import { ReactComponent as Like } from './static/like.svg'
import { ReactComponent as Laptop } from './static/laptop.svg'
import s from './chiefClientFace.module.css'

const ChiefClientFace = () => {
  return (
    <div className={s.wrapper}>
      <Face className={s.face} />
      <div className={s.animationHolder}>
        <React.Fragment>
          <Magic className={cn(s.sign, s.magic)} />
          <Like className={cn(s.sign, s.like)} />
          <Laptop className={cn(s.sign, s.laptop)} />
        </React.Fragment>
      </div>
    </div>
  )
}

export default ChiefClientFace
