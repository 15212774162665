import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import Index from 'pages/index'
import Chief from 'pages/chief'
import './App.css'

const YA_METRIKA = 33869584

const App = () => (
  <Router>
    <Switch>
      <Route path='/chief'>
        <Chief />
      </Route>
      <Route path='/'>
        <Index />
      </Route>
    </Switch>
    <YMInitializer accounts={[YA_METRIKA]} />
  </Router>
)

export default App
