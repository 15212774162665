import React, { useState, useRef, useLayoutEffect } from 'react'
import useWindow from 'helpers/useWindow'
import { ReactComponent as Scooter } from './static/balancing-scooter.svg'
import s from './chiefHelloAnimation.module.css'

const ChiefHelloAnimation = () => {
  const [scrollTop, viewportHeight] = useWindow()
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef()

  useLayoutEffect(() => {
    const top =
      ref.current.getBoundingClientRect().top +
      document.documentElement.scrollTop
    setIsVisible(scrollTop + viewportHeight * 0.8 > top)
  }, [scrollTop, viewportHeight])

  return (
    <div ref={ref}>
      <Scooter
        className={`
          ${s.scooter}
          ${isVisible && s.visible}
        `}
      />
    </div>
  )
}

export default ChiefHelloAnimation
