import React from 'react'

const ChiefBanner = () => (
  <div className='row module'>
    <div className='cols module'>
      <div className='col module'>
        <div className='col-in module'>{/* <ChiefBanner /> */}</div>
      </div>
    </div>
  </div>
)

export default ChiefBanner
