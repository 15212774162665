import React from 'react'
import s from './button.module.css'

const Button = props => (
  <React.Fragment>
    {props.href && (
      <a
        className={props.small ? s.buttonSmall : s.button}
        onClick={props.onClick}
        href={props.href}
      >
        {props.children}
      </a>
    )}
    {!props.href && (
      <button
        className={props.small ? s.buttonSmall : s.button}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )}
  </React.Fragment>
)

export default Button
