import React from 'react'
import ym from 'react-yandex-metrika'
import Row from 'components/base/row'
import octopusImg from './static/octopus.png'
import conspectusImg from './static/conspectus.png'
import chiefImg from './static/chief.png'
import catImg from './static/cat.png'
import gunImg from './static/gun.png'
import s from './tools.module.css'

const Tools = () => (
  <Row>
    <div className='cols module'>
      <div className='col module'>
        <div className='col-in module'>
          <h2>Инструменты</h2>
          <div className='apps-holder'>
            <div className={s.grid}>
              <a className={s.link} href='/octopus'>
                <div className={s.app}>
                  <div
                    className={s.octopus}
                    style={{ backgroundImage: `url(${octopusImg})` }}
                  />
                  <div className={s.description}>
                    <p>Осьминожка навыков</p>
                  </div>
                </div>
              </a>
            </div>
            <div className={s.grid}>
              <a className={s.link} href='/conspectus'>
                <div className={s.app}>
                  <div
                    className={s.conspectus}
                    style={{ backgroundImage: `url(${conspectusImg})` }}
                  />
                  <div className={s.description}>
                    <p>Конспект</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className='apps-holder'>
            <div className={s.grid}>
              <a
                className={s.link}
                href='/chief/'
                onClick={() => ym('reachGoal', 'ASK_CHIEF_INDEX')}
              >
                <div className={s.app}>
                  <div
                    className={s.chief}
                    style={{ backgroundImage: `url(${chiefImg})` }}
                  />
                  <div className={s.description}>
                    <p>Посоветоваться с шефом</p>
                  </div>
                </div>
              </a>
            </div>
            <div className={s.grid} />
          </div>
          <h2
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.15)',
              paddingTop: '20px',
              marginTop: 0,
            }}
          >
            Игры
          </h2>
          <div className='apps-holder '>
            <div className={s.grid}>
              <a
                className={s.link}
                href='https://bureau.ru/books/fff/demo/15/?utm_source=fff-works&utm_medium=index'
              >
                <div className={s.game}>
                  <div
                    className={s.gun}
                    style={{ backgroundImage: `url(${gunImg})` }}
                  />
                  <div className={s.description}>
                    <p>Встреча</p>
                  </div>
                </div>
              </a>
            </div>
            <div className={s.grid}>
              <a
                className={s.link}
                href='https://bureau.ru/books/fff/demo/17/?utm_source=fff-works&utm_medium=index'
              >
                <div className={s.game}>
                  <div
                    className={s.cat}
                    style={{ backgroundImage: `url(${catImg})` }}
                  />
                  <div className={s.description}>
                    <p>Проект</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Row>
)

export default Tools
