import React from 'react'
import cn from 'classnames'
import s from './chief.module.css'

const Section = props => (
  <div
    className={cn(s.section, props.className)}
    style={{ '--background': props.background }}
  >
    {props.children}
  </div>
)

export default Section
