const colors = {
  yellow: '#ffd000',
  ultramarine: '#2c159d',
  purpur: '#e44a66',
}

export default {
  mobileBreakpoint: '1023px',
  ...colors,
}
